import { render, staticRenderFns } from "./ConfigPay.vue?vue&type=template&id=152b860a&"
import script from "./ConfigPay.vue?vue&type=script&lang=js&"
export * from "./ConfigPay.vue?vue&type=script&lang=js&"
import style0 from "./ConfigPay.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCheckbox,VContainer,VFlex,VLayout,VSimpleTable})
