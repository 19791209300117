import validate from '@/mixins/validate'
import masks from '@/mixins/masks'
import DisplayField from '@/components/ui/DisplayField'
import EditActions from '@/components/ui/EditActions'

export default {
  mixins: [ validate, masks ],
  components: { DisplayField, EditActions },
  props: {
    items: {
      type: Object
      // required: true // called by route cause this warning
    },
    submitLabel: {
      type: String,
      default: 'Salvar'
    },
    btnCancelLabel: {
      type: String,
      default: 'Cancelar'
    },
    btnCancelShow: {
      type: Boolean,
      default: false
    },
    btnPosition: {
      type: String,
      default: 'right'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideSubmit: {
      type: Boolean,
      default: false
    },
    displayMode: {
      type: Boolean,
      default: false
    },
    actionsEdit: {
      type: Boolean,
      default: false
    },
    hidePassExpenses: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      isDisabled: false,
      filterSelect (item, queryText, itemText) {
        const hasValue = val => val != null ? val : ''
        const text = hasValue(item.label)
        const query = hasValue(queryText)
        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },
      isDisplayMode: true
    }
  },
  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.params)
        this.isDisabled = this.editAction
      }
    },
    edit () {
      this.$emit('edit')
    },
    cancel () {
      this.$emit('cancel')
    },
    changeDisplay (status) {
      this.isDisplayMode = status
    }
  },
  computed: {
    setStyleEditMode () {
      return this.actionsEdit ? 'margin-top: -47px' : ''
    }
  },
  watch: {
    isDisplayMode (val) {
      this.$emit('displayMode', val)
    },
    items (val) {
      // this.isDisplayMode = true
    }
  },
  mounted () {
    this.isDisplayMode = this.displayMode
  }
}
