<template>
  <v-container
    grid-list-lg
    :style="setStyleEditMode"
    class="pa-0">
    <h2 class="subtitle-1 mb-3">
      Informações para cálculo
    </h2>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-simple-table v-if="displayMode" dense>
        <template v-slot:default>
          <tbody>
            <display-field label="Correção Monetária" :value="params.correctionIndex" />
            <display-field suffix="%" label="Multa Moratória" :value="items.fine" />
            <display-field suffix="%" label="Juros Moratórios" :value="items.interestRate" />
            <display-field label="Juros Parcelamento" suffix="%" :value="items.rateInstallments" />
            <display-field suffix="%" label="Honorários Advocatícios" :value="items.fees" />
            <display-field label="Repassar despesas" :value="statusExpenses" />
            <slot />
          </tbody>
        </template>
      </v-simple-table>

      <v-layout
        v-else
        class="mt-8"
        row
        wrap>
        <edit-actions
          v-if="actionsEdit"
          :valid="valid"
          :display-mode="isDisplayMode"
          @change="changeDisplay"
          @save="save" />

        <v-flex
          xs6
          sm4>
          <v-select
            v-model.number="params.correctionIndex"
            value="id"
            item-text="id"
            item-value="id"
            class="paramsCorrectionIndex"
            label="Correção Monetária"
            :items="indexes"
            :rules="requiredRules"
            :disabled="isDisabled"
            required />
        </v-flex>

        <v-flex
          xs6
          sm4>
          <v-text-field
            v-model.number="params.fine"
            data-cy="paramsFine"
            label="Multa Moratória"
            suffix="%"
            :rules="percentageRules"
            :disabled="isDisabled"
            required />
        </v-flex>
        <v-flex
          xs6
          sm4>
          <v-text-field
            v-model.number="params.interestRate"
            data-cy="paramsInterestRate"
            label="Juros Moratórios"
            suffix="%"
            :rules="percentageRules"
            :disabled="isDisabled"
            required />
        </v-flex>
        <v-flex
          xs6
          sm4>
          <v-text-field
            v-model.number="params.rateInstallments"
            data-cy="paramsRateInstallments"
            label="Juros de Parcelamento"
            suffix="%"
            :rules="percentageRules"
            :disabled="isDisabled"
            required />
        </v-flex>
        <v-flex
          xs6
          sm4>
          <v-text-field
            v-model.number="params.fees"
            data-cy="paramsFees"
            label="Honorários Advocatícios"
            suffix="%"
            :rules="percentageRules"
            :disabled="isDisabled"
            required />
        </v-flex>

        <v-flex
          v-if="!hidePassExpenses"
          xs6
          sm4>
          <div>
            <v-switch
              v-model="params.passExpenses"
              label="Repassar despesas" />
          </div>
        </v-flex>
      </v-layout>

      <div
        :class="`text-${btnPosition}`"
        class="actions-step-fixed mb-2">
        <v-btn
          v-if="btnCancelShow"
          class="textColor--text mr-2"
          depressed
          text
          @click.native="cancel">
          {{ btnCancelLabel }}
        </v-btn>
        <v-btn
          v-if="!hideSubmit"
          color="accent"
          data-cy="paramsSubmit"
          :disabled="!valid"
          @click="submit">
          {{ submitLabel }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import eventsParamsMixin from '@/mixins/events/events-params'

  export default {
    mixins: [ eventsParamsMixin ],
    watch: {
      items (params) {
        this.params = params
      }
    }
  }
</script>
