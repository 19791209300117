<template>
  <v-container grid-list-lg>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <div
        v-resize="onResize"
        class="height-auto"
        :style="setAutoHeight">
        <v-layout
          class="layout-block"
          row
          wrap>
          <edit-actions
            v-if="actionsEdit"
            :valid="valid"
            :display-mode="isDisplayMode"
            @change="changeDisplay"
            @save="save" />
          <v-flex
            xs12
            sm4
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="Valor unitário"
              prefix="R$"
              :value="items.unitPrice" />
            <v-text-field
              v-else
              v-model="dataItems.unitPrice"
              v-money="money"
              label="Valor unitário"
              prefix="R$"
              data-cy="planUnitPrice"
              :rules="requiredRules"
              :disabled="isDisabled"
              required />
          </v-flex>
          <!-- <v-flex xs12 sm4 class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              prefix="R$"
              :value="items.letterPrice"
              label="Valor da carta">
            </display-field>
            <v-text-field
              v-else
              label="Valor da carta"
              v-model="dataItems.letterPrice"
              :rules="requiredRules"
              required
              prefix="R$"
              v-money="money"
              :disabled="isDisabled"
            ></v-text-field>
          </v-flex> -->
          <v-flex
            xs12
            sm4
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              prefix="R$"
              label="Valor por mediação"
              :value="items.mediationPrice" />
            <v-text-field
              v-else
              v-model="dataItems.mediationPrice"
              v-money="money"
              label="Valor por mediação"
              data-cy="planMediationPricee"
              prefix="R$"
              :rules="requiredRules"
              :disabled="isDisabled"
              required />
          </v-flex>
          <!-- <v-flex xs12 sm4 class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              :value="items.freeCases"
              suffix="casos"
              label="Degustação">
            </display-field>
            <v-text-field
              v-else
              label="Degustação"
              placeholder="X"
              suffix="casos"
              v-model="dataItems.freeCases"
              :rules="requiredRules"
              required
              :disabled="isDisabled"
            ></v-text-field>
          </v-flex> -->
          <v-flex
            xs6
            sm4
            class="forced-flex"
            data-cy="planPayAt">
            <display-field
              v-if="isDisplayMode"
              label="Será pago todo dia"
              :value="items.payAt" />
            <v-select
              v-else
              v-model="dataItems.payAt"
              label="Será pago todo dia"
              :menu-props="{contentClass:'planPayAt'}"
              :items="typesOptions"
              :rules="requiredRules"
              :disabled="isDisabled"
              required />
          </v-flex>

          <v-flex
            xs6
            sm4>
            <display-field
              v-if="displayMode"
              label="Repassar despesas"
              :value="displayExpenses" />
            <div v-else>
              <v-switch
                v-model="dataItems.passExpenses"
                label="Repassar despesas" />
            </div>
          </v-flex>
        </v-layout>
      </div>
      <div
        class="pb-2 actions-step-fixed"
        :class="`text-${btnPosition}`">
        <v-btn
          v-if="btnCancelShow"
          title="Voltar ao passo anterior"
          class="textColor--text"
          depressed
          text
          @click.native="cancel">
          {{ btnCancelLabel }}
        </v-btn>
        <v-btn
          v-if="!hideSubmit"
          color="accent"
          data-cy="planSubmit"
          :disabled="!valid"
          :loading="isLoading"
          @click="submit">
          {{ submitLabel }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import formsMixin from '@/mixins/forms'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ validate, masks, formsMixin ],
    data () {
      return {
        dataItems: {},
        typesOptions: ['10', '20', '30']
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      }),
      displayExpenses () {
        return this.items.passExpenses ? 'Sim' : 'Não'
      }
    },
    mounted () {
      this.dataItems = Object.assign({}, this.items)
    }
  }
</script>
