<template>
  <tr v-if="table" class="flex py-0 display-field xs12 sm12">
    <td class="table-text text-left font-weight-bold">
      {{ label }}
    </td>
    <td class="table-text text-right">
      {{ setStatus }}
    </td>
  </tr>
  <h2
    v-else
    class="subtitle-1 display-checked"
    :class="checkedStyle">
    <v-icon
      v-if="status"
      size="22px"
      class="icon-align-text mr-2"
      color="darkGray">
      {{ $root.icons.state.actived }}
    </v-icon>
    <v-icon
      v-else
      size="22px"
      class="icon-align-text mr-2"
      color="grey">
      {{ $root.icons.state.disabled }}
    </v-icon>
    {{ label }}
    <small class="caption ml-1 grey--text">({{ setStatus }})</small>
  </h2>
</template>

<script>
  export default {
    props: {
      status: {
        type: Boolean,
        required: true,
        default: false
      },
      label: {
        type: String,
        required: true
      },
      table: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      checkedStyle () {
        return this.status ? 'darkGray--text' : 'grey--text'
      },
      setStatus () {
        if (this.table) {
          return this.status ? 'Sim' : 'Não'
        }
        return this.status ? 'ativado' : 'desativado'
      }
    }
  }
</script>

<style lang="sass">
  .display-checked
    display: flex
    align-items: center
</style>
