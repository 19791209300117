<template>
  <v-chip
    class="channel-card pr-1"
    :color="stateCheckedColor"
    pill>
    <v-avatar
      size="12px !important"
      class="white channel-card__avatar">
      <v-icon :color="stateCheckedColor" size="18px">
        {{ icon }}
      </v-icon>
    </v-avatar>

    <span
      class="white--text label-icon-chip__label ml-6"
      :class="[isChecked ? 'white--text' : 'mediumGray--text']">
      {{ title }}
    </span>
    <v-spacer />

    <v-tooltip
      bottom
      light
      max-width="300">
      <template v-slot:activator="{ on }">
        <v-icon
          :color="isChecked ? 'white' : 'mediumGray'"
          class="info-icon mr-2"
          medium
          v-on="on">
          info_outline
        </v-icon>
      </template>

      <slot />
    </v-tooltip>

    <v-switch
      v-if="!displayMode"
      v-model="isChecked"
      class="ma-0 pa-0"
      color="white"
      :data-cy="kind"
      :disabled="displayMode"
      :hide-details="true"
      :rules="rules"
      light
      @change="change" />
  </v-chip>
</template>

<script>
  export default {
    props: {
      small: {
        type: Boolean,
        default: false
      },
      checked: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'accent'
      },
      noLabel: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        require: true
      },
      title: {
        type: String,
        require: true
      },
      minHeight: {
        type: String,
        default: '1px'
      },
      displayMode: {
        type: Boolean,
        default: false
      },
      rules: {
        type: Array || String,
        default: () => []
      },
      kind: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        isChecked: this.checked
      }
    },
    computed: {
      stateCheckedLabel () {
        if (this.noLabel) {
          return ''
        } else {
          return this.isChecked ? 'Ativado' : 'Desativado'
        }
      },
      stateCheckedColor () {
        return this.isChecked ? this.color : 'grayLigth'
      },
      setStyle () {
        return { 'min-height': this.minHeight }
      },
      sizeAvatar () {
        return this.small ? '40px' : '60px'
      },
      sizeIcon () {
        return this.small ? '24px' : '36px'
      }
    },
    watch: {
      checked (result) {
        this.isChecked = result
      }
    },
    methods: {
      change (isChecked) {
        this.isChecked = isChecked
        this.$emit('change', this.kind, isChecked)
      }
    }
  }
</script>

<style lang="sass">
  .channel-card
    display: flex

    &--errors
      .v-avatar
        box-shadow: 0px 0px 0px 2px var(--v-error-base), 0px 0px 0px 8px #FFFFFF !important

        .v-icon
          color: var(--v-error-base) !important

      .v-input--selection-controls__ripple,
      .v-input--switch__track,
      .v-input--switch__thumb
        color: #fff !important

    .v-chip__content
      width: 100%

    .v-avatar
      margin-left: -1px
      box-shadow: 0px 0px 0px 4px $bgColor, 0px 0px 0px 8px $light

    .v-icon.info-icon
      width: 24px
      justify-content: initial

</style>
