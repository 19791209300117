<template>
  <v-layout
    row
    justify-center>
    <v-dialog
      v-model="show"
      attach="main"
      content-class="config-page"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card
        tile
        light
        color="bgColor">
        <v-toolbar
          fixed
          height="50px"
          flat
          dark
          color="primary"
          elevation="0">
          <v-toolbar-title class="d-flex">
            <v-icon class="mr-1">
              {{ $root.icons.link.settings }}
            </v-icon>Configurações
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click.native="close">
            <v-icon>{{ $root.icons.action.close }}</v-icon>
          </v-btn>
        </v-toolbar>
        <template>
          <v-container
            grid-list-lg
            class="pt-1 config-wrapper"
            fluid>
            <template>
              <v-tabs
                class="tabs-transparent"
                slider-color="accent">
                <v-tab
                  exact
                  ripple
                  :to="{ name: 'Configuration' }">
                  Geral
                </v-tab>
                <v-tab
                  exact
                  ripple
                  :to="{name: 'Users', params: {group: 'supervisor'} }">
                  Usuários
                </v-tab>
                <v-tab
                  v-if="$can('Theme')"
                  exact
                  ripple
                  :to="{ name: 'ConfigurationTheme' }">
                  Tema
                </v-tab>
              </v-tabs>
              <v-card
                flat
                class="transparent content-tab">
                <router-view />
              </v-card>
            </template>
          </v-container>
        </template>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

  export default {
    data () {
      return {
        show: false,
        routerName: '',
        updateRouter: {}
      }
    },
    mounted () {
      this.checkRoute()
      this.show = true
    },
    beforeDestroy () {
      this.show = false
    },
    methods: {
      close () {
        let routerName = this.routerName
        let params = this.updateRouter.params
        this.show = false
        this.$router.push({ name: routerName, params })
      },
      checkRoute () {
        if (this.$route.params.routeName === undefined) {
          this.routerName = 'Home'
        } else {
          this.routerName = this.$route.params.routeName
          this.updateRouter = Object.assign({}, this.$route)
        }
      }
    }
  }
</script>
