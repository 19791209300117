<template>
  <v-container
    grid-list-lg
    class="area-pallets pa-0">
    <v-layout
      v-for="(pallet, index) in colors"
      :key="index"
      wrap>
      <v-flex
        xs2
        sm2
        class="pr-0">
        <v-menu
          v-model="isShowMenu[index]"
          nudge-top="-30"
          offset-x
          :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <div
              class="area-pallets-color"
              :style="`backgroundColor: ${pallet.color}`"
              v-on="on" />
          </template>

          <v-card>
            <chrome-picker
              v-model="pallet.color"
              @input="setColor(pallet.label, index, $event)" />
          </v-card>
        </v-menu>
      </v-flex>
      <v-flex
        xs10
        sm10
        class="pl-0">
        <small>{{ pallet.description }}</small>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { Chrome } from 'vue-color'

  export default {
    components: {
      'chrome-picker': Chrome
    },
    props: {
      colors: {
        type: Array,
        required: true
      },
      id: {
        type: String
      }
    },
    data () {
      return {
        isShowMenu: []
      }
    },
    methods: {
      save () {
        this.firebase.theme.child('theme').set(this.$vuetify.theme.themes.light)
      },
      setColor (label, index, color) {
        let data = { label, color: color.hex, index }
        this.$emit('change', data)
      }
    }
  }
</script>

<style lang="sass">
  @import '../../assets/style/placeholder'

  .area-pallets
    &-color
      padding: 10px 5px
      color: #fff
      width: 30px
      font-size: 11px
      @extend %default-shadow

    .v-menu.v-menu--inline
      display: none
</style>


