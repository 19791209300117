<template>
  <v-flex
    xs12
    sm12>
    <v-card height="100%">
      <v-card-text class="pb-2">
        <h2 class="subtitle-1 darkGray--text">
          Pré visualização
        </h2>
      </v-card-text>

      <v-divider />

      <v-card-text>
        <v-layout wrap>
          <v-flex
            xs12
            sm8>
            <h3 class="body-2 darkGray--text">
              Botões
            </h3>

            <v-flex
              xs12
              sm12>
              <v-btn
                depressed
                small
                color="primary"
                class="mx-2 my-1">
                primária
              </v-btn>
              <v-btn
                depressed
                small
                color="accent"
                class="mx-2 my-1">
                secondária
              </v-btn>
              <v-btn
                depressed
                small
                color="secondary"
                class="mx-2 my-1">
                terciária
              </v-btn>
              <v-btn
                depressed
                small
                color="success"
                class="mx-2 my-1">
                successo
              </v-btn>
              <v-btn
                depressed
                small
                color="info"
                class="mx-2 my-1">
                informativo
              </v-btn>
              <v-btn
                depressed
                small
                color="warning"
                class="mx-2 my-1">
                atenção
              </v-btn>
              <v-btn
                depressed
                small
                color="error"
                class="mx-2 my-1">
                erro
              </v-btn>
            </v-flex>
          </v-flex>

          <v-flex
            xs12
            sm4>
            <h3 class="body-2 darkGray--text">
              Loading
            </h3>

            <v-flex
              xs12
              sm12
              class="demo-loading-wrapper">
              <span class="demo-loading"><loading /></span>
            </v-flex>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-flex
            xs12
            sm6>
            <h3 class="body-2 darkGray--text">
              Fluxos
            </h3>

            <v-flex
              xs12
              sm12>
              <label-icon
                type="Email"
                class="mx-1" />
              <label-icon
                type="Sms"
                class="mx-1" />
              <label-icon
                type="Carta"
                class="mx-1" />
            </v-flex>
          </v-flex>

          <v-flex
            xs12
            sm6>
            <h3 class="body-2 darkGray--text">
              Logos
            </h3>

            <v-flex
              xs12
              sm12>
              <v-layout pa-2>
                <v-flex
                  xs6
                  sm6>
                  <v-layout
                    column
                    align-center>
                    <img
                      :src="getLogo"
                      class="logo-preview"
                      alt="Logo">
                    <span>Logo</span>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs6
                  sm6>
                  <v-layout
                    column
                    align-center>
                    <img
                      :src="getMiniLogo"
                      class="logo-preview"
                      alt="Mini Logo">

                    <span>Mini Logo</span>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
  import Loading from '@/components/ui/Loading'
  import LabelIcon from '@/components/ui/LabelIcon'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Loading,
      LabelIcon
    },
    computed: {
      ...mapGetters('global', ['getLogo', 'getMiniLogo'])
    }
  }
</script>

<style lang="sass">
  .demo-loading-wrapper
    height: 90px

  .demo-loading
    position: relative
    top: 40px
    left: 50%
    transform: translateX(-50%)

  .logo-preview
    height: 45px
</style>
