<template>
  <v-container
    grid-list-lg
    :style="setStyleEditMode">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-layout
        row
        wrap>
        <edit-actions
          v-if="actionsEdit"
          :valid="valid"
          :display-mode="isDisplayMode"
          @change="changeDisplay"
          @save="save" />

        <v-layout row>
          <v-flex
            xs12
            sm4>
            <display-field
              v-if="isDisplayMode"
              label="Proposta Inicial expira em"
              suffix="dias"
              :value="expirations.initialProposal" />


            <v-flex
              v-else
              xs12
              sm12>
              <div class="input-group label-show input-group--dirty input-group--text-field input-group--suffix primary--text">
                <label class="label">Proposta Inicial expira em</label>
              </div>
              <v-text-field
                v-model="expirations.initialProposal"
                class="pt-0"
                mask="##"
                label=""
                required
                :rules="maxValue90"
                suffix="dias"
                :disabled="isDisabled" />
            </v-flex>
          </v-flex>

          <v-flex
            xs12
            sm4>
            <display-field
              v-if="isDisplayMode"
              label="Negociação expira em"
              suffix="dias"
              :value="expirations.negotiation" />

            <v-flex
              v-else
              xs12
              sm12>
              <div class="input-group label-show input-group--dirty input-group--text-field input-group--suffix primary--text">
                <label class="label">Negociação expira em</label>
              </div>
              <v-text-field
                v-model="expirations.negotiation"
                class="pt-0"
                required
                :rules="maxValue60"
                mask="##"
                suffix="dias"
                :disabled="isDisabled" />
            </v-flex>
          </v-flex>


          <v-flex
            xs12
            sm4>
            <display-field
              v-if="isDisplayMode"
              label="Mediação expira em"
              suffix="dias"
              :value="expirations.mediation" />

            <v-flex
              v-else
              xs12
              sm12>
              <div class="input-group label-show input-group--dirty input-group--text-field input-group--suffix primary--text">
                <label class="label">Mediação expira em</label>
              </div>
              <v-text-field
                v-model="expirations.mediation"
                class="pt-0"
                required
                :rules="maxDayMediation"
                mask="##"
                suffix="dias"
                :disabled="isDisabled" />
            </v-flex>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
  import formsMixin from '@/mixins/forms'
  export default {
    mixins: [ formsMixin ],
    props: {
      items: {
        type: Object,
        required: false
      }
    },
    data () {
      return {
        // TODO
        // Definir os valores default e adicionar uma validação
        expirations: {
          initialProposal: 90,
          negotiation: 60,
          mediation: 60
        }
      }
    },
    computed: {
      hasTicket () {
        return this.dataItems.isCheckedTicket ? 'Sim' : 'Não'
      },
      hasCreditCard () {
        return this.items.isCheckedCreditCard ? 'Sim' : 'Não'
      }
    },
    methods: {
      changeDisplay (status) {
        this.isDisplayMode = status
      },
      save () {
        this.$emit('save', this.expirations)
        this.isDisplayMode = true
      }
    }
  }
</script>
