import axios from 'axios'
import { AUTH_TOKEN } from '@/utils/index'

const token = localStorage.getItem(AUTH_TOKEN)

export default axios.create({
  responseType: 'blob',
  headers: {
    Authorization: token ? `Bearer ${token}` : ''
  }
})
