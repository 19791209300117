<template>
  <v-layout
    row
    align-center
    class="relative">
    <v-text-field
      v-model="fileName"
      :prepend-icon="$root.icons.layout.attach_file"
      single-line
      :label="value"
      :readonly="true"
      :data-cy="path"
      @click="$refs.inputUpload.click()" />

    <img
      v-if="logoPreview"
      :src="logoPreview"
      class="logo-preview"
      alt="Logo preview">

    <input
      v-show="false"
      :id="path"
      ref="inputUpload"
      type="file"
      @change="uploadImage">
  </v-layout>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      path: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        fileName: '',
        logoPreview: ''
      }
    },
    methods: {
      uploadImage (e) {
        const file = e.target.files[0]
        const reader = new FileReader()

        return new Promise(resolve => {
          reader.onload = () => {
            this.fileName = e.target.files[0].name
            this.logoPreview = reader.result
            this.$emit('fileChange', reader.result, this.path)
          }

          file ? reader.readAsDataURL(file) : resolve(null)
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  .logo-preview
    height: 22px
    position: absolute
    right: 0
</style>
