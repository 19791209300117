<template>
  <v-toolbar
    class="elevation-0 edit-actions"
    height="23"
    width="100%"
    color="transparent">
    <v-spacer />
    <v-btn
      v-if="isDisplayMode"
      color="bgColor"
      title="Editar Campos"
      class="mt-6 ml-6"
      icon
      @click="edit">
      <v-icon color="darkGray">
        {{ $root.icons.action.edit }}
      </v-icon>
    </v-btn>

    <template v-else>
      <slot name="extra-actions" />

      <v-btn
        v-blur
        title="Salvar edição"
        class="mt-4 elevation-0"
        :disabled="!valid"
        x-small
        @click="save">
        Salvar
      </v-btn>

      <v-btn
        v-blur
        title="Cancelar edição"
        class="mt-4 ml-2 elevation-0"
        x-small
        @click="cancel">
        Cancelar
      </v-btn>
    </template>
  </v-toolbar>
</template>

<script>
  export default {
    props: {
      displayMode: {
        type: Boolean,
        default: true
      },
      valid: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        isDisplayMode: {}
      }
    },
    watch: {
      displayMode (val) {
        this.isDisplayMode = val
      }
    },
    mounted () {
      this.isDisplayMode = this.displayMode
    },
    methods: {
      save () {
        // this.isDisplayMode = true
        this.$emit('save')
      },
      edit () {
        this.isDisplayMode = false
        this.$emit('change', this.isDisplayMode)
      },
      cancel () {
        this.isDisplayMode = true
        this.$emit('change', this.isDisplayMode)
        this.$emit('cancel', this.isDisplayMode)
      }
    }
  }
</script>

<style lang="sass">
  .edit-actions
    top: -15px
    margin-bottom: 15px

    // .v-btn--icon
    //   width: 36px !important
    //   height: 36px !important

  .config-page
    .edit-actions
      margin-top: -35px

      .v-btn
        background-color: transparent !important
        margin-top: 0 !important

  .user-show
    .edit-actions
      margin-top: 5px

      .v-btn
        background-color: transparent !important
        margin-top: 0 !important

        .v-icon
          color: var(--v-darkGray-base) !important

</style>
