<template>
  <v-container class="py-0 pl-0 ">
    <display-field
      v-if="isDisplayMode"
      class="mb-3"
      :value="hasResend"
      label="Possui repique" />
    <div
      v-else
      class="flex-field">
      <div class="input-group input-group--dirty input-group--text-field input-group--suffix primary--text">
        <label class="label">Deseja Repique ?</label>
      </div>
      <v-checkbox
        v-model="isChecked"
        :label="hasResend"
        class="simple-checkbox" />
    </div>
    <v-layout
      v-if="isChecked"
      row
      wrap>
      <v-flex
        v-for="(resend, index) in newData.peal"
        :key="index"
        xs12
        sm6
        class="pt-0">
        <div
          v-if="isDisplayMode"
          class="flex py-0 display-field">
          <p class="mb-0">
            <span class="darkGray--text mb-0">{{ setOrder(index) }}</span>
            <span class="body-2 mb-0 grey--text">
              {{ resend.value }} {{ suffix }}

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    small
                    class="elevation-0 mx-0"
                    v-on="on"
                    @click="showTemplate">
                    <v-icon
                      small
                      dark
                      color="accent">{{ $root.icons.layout.term }}</v-icon>
                  </v-btn>
                </template>
                <span>Exibir template</span>
              </v-tooltip>
            </span>
          </p>
        </div>

        <v-flex
          v-else
          xs4
          sm4>
          <v-text-field
            v-model.number="resend.value"
            :suffix="suffix"
            mask="##"
            class="value-field label-show"
            placeholder="X"
            :label="setOrder(index)"
            @keyup="sendData" />
        </v-flex>

        <div v-if="!isDisplayMode">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                small
                class="elevation-0 mx-0"
                v-on="on"
                @click="showTemplate()">
                <v-icon
                  small
                  dark
                  color="accent">
                  {{ $root.icons.layout.term }}
                </v-icon>
              </v-btn>
            </template>
            <span>Exibir template</span>
          </v-tooltip>
          <v-tooltip
            v-if="addResend"
            top>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                small
                class="elevation-0 mx-0"
                v-on="on"
                @click="resetActionsResend">
                <v-icon
                  small
                  dark
                  color="success">
                  {{ $root.icons.action.add }}
                </v-icon>
              </v-btn>
            </template>
            <span>Adicionar repique</span>
          </v-tooltip>
          <v-tooltip
            v-if="index > 0"
            top>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                small
                class="elevation-0 mx-0"
                v-on="on"
                @click="remove(resend)">
                <v-icon
                  small
                  dark
                  color="darkGray">
                  {{ $root.icons.action.delete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Remover repique</span>
          </v-tooltip>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import DisplayField from '@/components/ui/DisplayField'

  export default {
    components: { DisplayField },
    props: {
      suffix: {
        type: String,
        required: true
      },
      items: {
        type: Array
      },
      displayMode: {
        type: Boolean,
        default: false
      },
      checked: {
        type: Boolean,
        require: true
      }
    },
    data () {
      return {
        isChecked: null,
        isDisplayMode: false,
        newData: {
          hasPeal: false,
          peal: []
        }
      }
    },
    computed: {
      addResend () {
        return this.newData.peal.length < 3
      },
      hasResend () {
        return this.isChecked ? 'Sim' : 'Não'
      }
    },
    watch: {
      checked (val) {
        this.isChecked = val
      },
      isChecked (val) {
        this.newData.hasPeal = val
        val && this.sendData()
        !val && this.setDefaultPeal()
      }
    },
    mounted () {
      this.newData.peal = this.items
      this.isDisplayMode = this.displayMode
      this.isChecked = this.checked
    },
    methods: {
      setOrder (index) {
        let order = index + 1
        return `${order}º envio após `
      },
      resetResendEmailChecked () {
        this.isChecked = false
      },
      resetActionsResend () {
        let model = { value: '', template: '' }
        this.newData.peal.push(model)
        this.sendData()
      },
      sendData () {
        !this.displayMode && this.changeEvent()
      },
      changeEvent () {
        this.$emit('change', this.newData)
      },
      remove (resend) {
        this.newData.peal = this.newData.peal.filter(record => record !== resend)
        this.sendData()
      },
      showTemplate () {
        this.$emit('show')
      },
      setDefaultPeal () {
        this.newData.peal = [{ value: 0, template: '' }]
      }
    }
  }
</script>

<style lang="sass">
  .value-field
    float: left
    margin-right: 15px
    // width: 150px

  .flex-field
    display: flex

    .v-input-group
      flex: inherit
      width: 110px

      .label
        overflow: initial
        top: 20px

</style>
