<template>
  <v-container class="px-0 py-0 list-templates">
    <h2
      v-if="!hideTitle"
      class="subtitle">
      Termos
    </h2>
    <ul>
      <li>
        <a
          href=""
          class="text-center none-decoration"
          tabindex="0"
          @click.prevent="previewDocument('agree')">
          <v-icon
            size="40"
            color="mediumGray">{{ $root.icons.layout.term }}</v-icon>
          <span class="caption grey--text d-block">Acordo</span>
        </a>
      </li>
      <li>
        <a
          href=""
          class="text-center none-decoration ml-3"
          tabindex="0"
          @click.prevent="previewDocument('disagree')">
          <v-icon
            size="40"
            color="mediumGray">{{ $root.icons.layout.term }}</v-icon>
          <span class="caption grey--text d-block">Não acordo</span>
        </a>
      </li>
    </ul>

    <modal
      scrollable
      max-with="800px"
      :show="modalTemplate"
      hide-actions
      @close="closeModal">
      <span slot="title">{{ modalTemplateTitle }}</span>
      <div slot="content">
        <loading
          v-if="loadingTemplate < 1"
          class="absolute-center" />
        <pdf
          v-for="page in numPages"
          :key="page"
          :page="page"
          :src="urlTemplate"
          style="height: 100%"
          @page-loaded="loadTemplate" />
      </div>
    </modal>
  </v-container>
</template>

<script>
  import Loading from '@/components/ui/Loading'
  import Modal from '@/components/ui/Modal'
  import PdfTemplateModels from '@/mixins/pdf-template-models'

  export default {
    components: {
      Loading, Modal
    },
    mixins: [ PdfTemplateModels ],
    props: {
      hideTitle: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        modalTemplate: false,
        modalTemplateTitle: '',
        urlTemplate: null,
        loadingTemplate: 0,
        numPages: 1
      }
    },
    watch: {
      urlTemplate () {
        if (this.urlTemplate !== null) {
          this.urlTemplate.then(pdf => { this.numPages = pdf.numPages })
        }
      }
    },
    methods: {
      closeModal () {
        this.modalTemplate = false
        this.urlTemplate = null
      },
      previewDocument (kind) {
        this.loadingTemplate = 0
        this.modalTemplate = true
        switch (kind) {
        case 'agree': {
          this.modalTemplateTitle = 'Exemplo de Termo de Acordo'
          this.agree()
          break
        }
        case 'disagree': {
          this.modalTemplateTitle = 'Exemplo de Certificado de Recusa'
          this.disagree()
          break
        }
        }
      },
      loadTemplate (status) {
        this.loadingTemplate = status
      }
    }
  }
</script>

<style lang="sass" scoped>
  .list-templates

    .subtitle
      margin-bottom: 10px
      color: $darkGray
      font-size: 16px
      font-weight: 400

    ul
      display: flex
      list-style: none

    li
      text-align: center
      margin-right: 20px

    a
      transform: scale(1)
      transition: all .25s ease-out
      display: block

      &:hover,
      &:focus,
      &:active
        transform: scale(1.1)
</style>
