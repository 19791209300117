<template>
  <v-layout
    row
    wrap
    class="mt-2">
    <v-flex
      xs12
      sm6>
      <v-card>
        <v-card-title
          primary-title
          class="py-0 px-0 title-color">
          <v-card-text class="py-2">
            <h2 class="subtitle-1">
              <v-icon class="mr-1">
                {{ $root.icons.layout.percent }}
              </v-icon>
              Informações para Cálculo Padrão
            </h2>
          </v-card-text>
        </v-card-title>
        <v-divider />

        <event-params
          actions-edit
          hide-submit
          :display-mode="displayModeFinancial"
          :items="calculateParams"
          @save="saveEditParams"
          @displayMode="changeDisplayModeFinancial" />
      </v-card>
    </v-flex>
    <v-flex
      xs12
      sm6>
      <v-card>
        <v-card-title
          primary-title
          class="py-0 px-0 title-color">
          <v-card-text class="py-2">
            <h2 class="subtitle-1">
              <v-icon class="mr-1">
                {{ $root.icons.link.channels }}
              </v-icon>
              Canais de comunicação
            </h2>
          </v-card-text>
        </v-card-title>
        <v-divider />

        <event-channels
          :items="channels"
          hide-submit
          @change="saveEditChannels"
          @displayMode="changeDisplayModeChannels" />
      </v-card>
    </v-flex>
    <v-flex
      xs12
      sm6>
      <v-card>
        <v-card-title
          primary-title
          class="py-0 px-0 title-color">
          <v-card-text class="py-2">
            <h2 class="subtitle-1">
              <v-icon class="mr-1">
                {{ $root.icons.link.payment }}
              </v-icon>
              Opções de pagamento
            </h2>
          </v-card-text>
        </v-card-title>
        <v-divider />

        <v-layout row wrap px-2>
          <v-flex px-2>
            <label-icon
              class="mr-3"
              type="ticket"
              label="Boleto"
              :disabled="!payments.ticket"
              large />

            <label-icon
              class="mr-3"
              type="creditCard"
              label="Cartão de Crédito"
              :disabled="!payments.creditCard"
              large />

            <label-icon
              class="mr-3"
              type="generateFile"
              label="Arquivo"
              :disabled="!payments.generateFile"
              large />

            <label-icon
              class="mr-3"
              type="reports"
              label="Relatório"
              :disabled="!payments.reports"
              large />
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      sm6>
      <v-card>
        <v-card-title
          primary-title
          class="py-0 px-0 title-color">
          <v-card-text class="py-2">
            <h2 class="subtitle-1">
              <v-icon class="mr-1">
                {{ $root.icons.link.settings }}
              </v-icon>
              Outras configurações
            </h2>
          </v-card-text>
        </v-card-title>
        <v-divider />

        <config-others
          :display-mode="displayModeOthers"
          :items="payments"
          @save="saveEditOthers"
          @displayMode="changeDisplayModeOthers" />
      </v-card>
    </v-flex>
    <v-flex
      xs12
      sm6>
      <v-card>
        <v-card-title
          primary-title
          class="py-0 px-0 title-color">
          <v-card-text class="py-2">
            <h2 class="subtitle-1">
              <v-icon class="mr-1">
                {{ $root.icons.layout.files }}
              </v-icon>
              Templates
            </h2>
          </v-card-text>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <p class="textColor--text">
            Clique e visualize os modelos utilizados em nossa plataforma
          </p>
          <config-pdf-templates />
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      sm6>
      <v-card>
        <v-card-title
          primary-title
          class="py-0 px-0 title-color">
          <v-card-text class="py-2">
            <h2 class="subtitle-1">
              <v-icon class="mr-1">
                {{ $root.icons.link.invoice }}
              </v-icon>Plano
            </h2>
          </v-card-text>
        </v-card-title>
        <v-divider />
        <form-plan
          :items="plan"
          hide-submit
          display-mode />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import FormPlan from '@/components/ui/forms/FormPlan'
  import EventParams from '@/components/ui/events/EventParams'
  import EventChannels from '@/components/ui/events/EventChannels'
  import ConfigPdfTemplates from '@/components/ui/ConfigPdfTemplates'
  import ConfigPay from '@/components/ui/ConfigPay'
  import ConfigOthers from '@/components/ui/ConfigOthers'
  import { mapGetters } from 'vuex'
  import ChannelCard from '@/components/ui/ChannelCard'
  import LabelIcon from '@/components/ui/LabelIcon'

  export default {
    name: 'Configuration',
    components: {
      EventParams,
      EventChannels,
      FormPlan,
      ConfigPay,
      ConfigOthers,
      ConfigPdfTemplates,
      ChannelCard,
      LabelIcon
    },
    data () {
      return {
        displayModeFinancial: true,
        displayModeChannels: true,
        displayModePay: true,
        displayModeOthers: true,
        payments: {
          ticket: true,
          creditCard: false
        },
        payOptionsUpdated: {},
        othersConfig: {},
        userInfo: null,
        show: false
      }
    },
    computed: {
      ...mapGetters({
        plan: 'currentCompany/plan',
        channels: 'currentCompany/channels',
        calculateParams: 'currentCompany/calculateParams',
        companyId: 'currentCompany/id'
      })
    },
    watch: {
      isLogout (status) {
        status && this._setStateLogout()
      },
      displayModeFinancial (val) {
        !val && this.closeSnackbar()
      },
      displayModeChannels (val) {
        !val && this.closeSnackbar()
      },
      displayModePay (val) {
        !val && this.closeSnackbar()
      },
      displayModeOthers (val) {
        !val && this.closeSnackbar()
      }
    },
    mounted () {
      this.show = true
    },
    methods: {
      changeDisplayModeFinancial (status) {
        this.displayModeFinancial = status
      },
      saveEditParams (data) {
        this.$root.$emit('snackbar', {show: false})
        console.debug(`Sending calculateParams ${JSON.stringify(data)}`)
        this
          .$store
          .dispatch('companies/update', {companyId: this.companyId, companyParams: {calculateParams: data}})
          .then(this._successParams)
          .catch(this._failure)
      },
      changeDisplayModeChannels (status) {
        this.displayModeChannels = status
      },
      saveEditChannels (data) {
        this.$root.$emit('snackbar', {show: false})
        console.debug(`Sending channels ${JSON.stringify(data)}`)
        this
          .$store
          .dispatch('companies/update', {companyId: this.companyId, companyParams: {channels: data}})
          .then(this._successChannel)
          .catch(this._failure)
      },
      closeSnackbar () {
        this.$root.$emit('snackbar', {show: false})
      },
      changeDisplayModePay (status) {
        this.displayModePay = status
      },
      saveEditPay (dataUpdated) {
        this.$root.$emit('snackbar', {show: false})
        console.log('saveEditPay', dataUpdated)
        this.payOptionsUpdated = dataUpdated
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Opções de pagamento atualizados'})
      },
      changeDisplayModeOthers (status) {
        this.displayModeOthers = status
      },
      saveEditOthers (dataUpdated) {
        this.$root.$emit('snackbar', {show: false})
        console.log('saveEditOthers', dataUpdated)
        this.othersConfig = dataUpdated
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Data de expiração atualizada'})
      },
      close () {
        this.show = false
        setTimeout(() => this.$router.go(-1), 200)
      },
      _failure () {
        this.$root.$emit('snackbar', {show: false})
        this.$nextTick(() => {
          this.$root.$emit('snackbar', {show: true, color: 'error', text: 'houve um problema'})
        })
      },
      _successChannel (message) {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Canais de comunicação atualizados'})
      },
      _successParams (message) {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Informações para cálculo atualizados'})
      },
    }
  }
</script>
