<template>
  <v-chip
    class="label-icon-chip mr-1"
    :class="{ 'label-icon-chip--large': large }"
    :color="color"
    small>
    <v-avatar
      size="22px !important"
      class="white">
      <v-icon
        :color="color"
        small>
        {{ setIcon }}
      </v-icon>
    </v-avatar>

    <span class="white--text label-icon-chip__label">
      {{ setLabel }}
    </span>
  </v-chip>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        required: true
      },
      large: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      setIcon () {
        const labelType = {
          Email: this.$root.icons.channel.email,
          Sms: this.$root.icons.channel.sms,
          Carta: this.$root.icons.channel.letter,
          video: this.$root.icons.layout.video,
          phone: this.$root.icons.layout.phone,
          task: this.$root.icons.layout.task,
          ticket: this.$root.icons.layout.ticket,
          creditCard: this.$root.icons.layout.credit_card,
          generateFile: this.$root.icons.layout.generator_file,
          reports: this.$root.icons.link.reports,
        }

        return labelType[this.type]
      },
      color () {
        if (this.disabled) return '#ccc'

        const colors = {
          Email: 'channelEmail',
          Sms: 'channelSms',
          Carta: 'channelLetter',
          video: 'channelEmail',
          phone: 'channelSms',
          task: 'channelLetter',
          ticket: 'channelEmail',
          creditCard: 'channelSms',
          generateFile: 'channelLetter',
          reports: 'secondary',
        }

        return colors[this.type]
      },
      classColor () {
        return `${this.color}--text`
      },
      setLabel () {
        return this.label ? this.label : this.type
      }
    }
  }
</script>

<style lang="sass">
  .label-icon-chip
    width: 75px
    padding: 0

    &--large
      width: unset
      padding-right: 10px

    &__label
      margin-left: 5px
      font-size: 13px
      font-weight: 500

    .v-avatar
      margin-left: 1px

@media screen and (max-width: $max-mobile)
    .label-icon-chip
      width: 24px

      &__label
        display: none
</style>
