import eventsUiMixin from '@/mixins/events/events-ui'

export default {
  mixins: [ eventsUiMixin ],
  data: () => ({
    loadingIndexes: 0,
    indexes: [
      { id: 'selic' },
      { id: 'igpm' },
      { id: 'inpc' },
      { id: 'ipca' },
    ],
    params: {
      calculateParams: {
        correctionIndex: '',
        correctionIndexDiscount: 0,
        fine: '',
        interestRate: '',
        rateInstallments: '',
        fees: '',
        passExpenses: false
      },
      discount: {
        type: '',
        value: ''
      }
    },
    statusExpenses: ''
  }),
  methods: {
    save () {
      if (this.$refs.form.validate()) {
        // this.params.parcel.parcelOf = parseInt(this.params.parcel.parcelOf) + 1
        this.$emit('save', this.params)
        this.isDisplayMode = true
      }
    },
    setExpenses (val) {
      this.statusExpenses = val ? 'Sim' : 'Não'
    },
    setParams () {
      this.params = Object.assign({}, this.items)
    }
  },
  mounted () {
    this.isDisabled = this.disabled
    this.setParams()
    this.setExpenses(this.params.passExpenses)
  }
}
