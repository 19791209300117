<template>
  <v-container
    grid-list-lg
    fluid>
    <v-layout
      justify-space-between
      :column="isMobile">
      <colors />

      <preview />
    </v-layout>
  </v-container>
</template>

<script>
  import Colors from './Colors'
  import Preview from './Preview'

  export default {
    components: { Colors, Preview },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.smOnly ||
          this.$vuetify.breakpoint.xsOnly
      }
    }
  }
</script>
