import request from '@/client/templates'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('global', ['getHostApi'])
  },
  methods: {
    disagree () {
      request
        .post('/functions/templates', {
          template: 'TermDisagreement',
          params: {
            date: '30/09/2018',
            time: '15:34',
            correctionIndex: 'INPC',
            parcel: 3,
            termRegister: '################################',
            code: '####-####-######',
            company: {
              name: 'Nome Fantasia da Empresa',
              cnpj: 'XXXXXX XXXXX XXXXX',
              signature: {
                id: '################################',
                date: '##/##/##',
                time: '##:##:##'
              }
            },
            guest: {
              name: 'Nome do Convidado',
              email: 'email@convidado.com.br',
              document: '#### #### ####',
              signature: {
                id: '################################',
                date: '##/##/##',
                time: '##:##:##'
              }
            },
            fine: '2%',
            interestRate: '1%',
            correctedValue: 'R$ ######,##',
            negotiatedValue: 'R$ ######,##'
          }
        })
        .then(response => {
          this.loadingTemplate = false
          this.$nextTick(() => {
            const options = { pdfOpenParams: { view: 'FitH', page: '1' } }
            PDFObject.embed(URL.createObjectURL(response.data), "#pdfview", options)
          })
        })
    },
    agree () {
      request
        .post('/functions/templates', {
          template: 'TermAgreement',
          params: {
            date: '30/09/2018',
            time: '15:34',
            correctionIndex: 'INPC',
            parcel: 3,
            termRegister: '################################',
            code: '####-####-######',
            company: {
              name: 'Nome Fantasia da Empresa',
              cnpj: 'XXXXXX XXXXX XXXXX',
              signature: {
                id: '################################',
                date: '##/##/##',
                time: '##:##:##'
              }
            },
            guest: {
              name: 'Nome do Convidado',
              email: 'email@convidado.com.br',
              document: '#### #### ####',
              signature: {
                id: '################################',
                date: '##/##/##',
                time: '##:##:##'
              }
            },
            fine: '2%',
            interestRate: '1%',
            correctedValue: 'R$ ######,##',
            negotiatedValue: 'R$ ######,##'
          }
        })
        .then(response => {
          this.loadingTemplate = false
          this.$nextTick(() => {
            const options = { pdfOpenParams: { view: 'FitH', page: '1' } }
            PDFObject.embed(URL.createObjectURL(response.data), "#pdfview", options)
          })
        })
    }
  }
}
