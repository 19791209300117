<template>
  <v-container
    grid-list-lg
    :style="setStyleEditMode"
    class="config-payments">
    <h2 class="subtitle-1 mb-2">
      Meio de pagamento
    </h2>

    <v-simple-table v-if="displayMode" dense>
      <template v-slot:default>
        <tbody>
          <display-checked label="Pagar.me" :status="dataItems.pagarme" table />
          <display-checked label="Boleto" :status="dataItems.ticket" table />
          <display-checked label="Cartão de crédito" :status="dataItems.creditCard" table />
        </tbody>
      </template>
    </v-simple-table>

    <v-layout
      v-else
      row
      wrap
      class="px-6">
      <edit-actions
        v-if="actionsEdit"
        :valid="valid"
        :display-mode="isDisplayMode"
        @change="changeDisplay"
        @save="save" />

      <v-flex
        xs6
        sm6
        class="forced-flex px-0">
        <div>
          <div class="input-group input-group--dirty input-group--text-field input-group--suffix primary--text">
            <label class="label">Pagar.me</label>
          </div>
          <v-checkbox
            v-model="dataItems.pagarme"
            :label="hasTicket"
            class="simple-checkbox" />
        </div>
      </v-flex>

      <v-flex
        xs6
        sm6
        class="forced-flex px-0">
        <div>
          <div class="input-group input-group--dirty input-group--text-field input-group--suffix primary--text">
            <label class="label">Boleto</label>
          </div>
          <v-checkbox
            v-model="dataItems.ticket"
            :label="hasTicket"
            class="simple-checkbox" />
        </div>
      </v-flex>

      <v-flex
        xs6
        sm6
        class="forced-flex px-0">
        <div>
          <div class="input-group input-group--dirty input-group--text-field input-group--suffix primary--text">
            <label class="label">Cartão de crédito</label>
          </div>
          <v-checkbox
            v-model="dataItems.creditCard"
            :label="hasCreditCard"
            class="simple-checkbox" />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import formsMixin from '@/mixins/forms'
  import DisplayChecked from '@/components/ui/DisplayChecked'

  export default {
    components: { DisplayChecked },
    mixins: [ formsMixin ],
    computed: {
      hasTicket () {
        return this.dataItems.ticket ? 'Sim' : 'Não'
      },
      hasCreditCard () {
        return this.items.creditCard ? 'Sim' : 'Não'
      },
      hasPagarme () {
        return this.items.pagarme ? 'Sim' : 'Não'
      }
    },
    mounted () {
      this.dataItems = this.items
    },
    methods: {
      changeDisplay (status) {
        this.isDisplayMode = status
      }
    }
  }
</script>

<style lang="sass">
  .contextual-area
    .config-payments

      .forced-flex
        flex-basis: 100%!important
        max-width: 100%!important

        &:first-child
          border-bottom: 1px solid $borderColor
          margin-bottom: 10px
          padding-bottom: 20px
</style>
