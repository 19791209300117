<template>
  <v-flex
    xs12
    sm12>
    <v-card height="100%">
      <v-card-text class="pa-0">
        <v-tabs v-model="model">
          <v-tab href="#tab1">
            Cores
          </v-tab>
          <v-tab href="#tab2">
            Logos
          </v-tab>

          <div class="theme-colors-actions">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="btn-save"
                  :disabled="!valid"
                  icon
                  small
                  v-on="on"
                  @click="openModal">
                  <v-icon color="accent">
                    {{ $root.icons.action.save }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Salvar edição</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="btn-close"
                  :disabled="!valid"
                  icon
                  small
                  v-on="on"
                  @click="reset">
                  <v-icon color="accent">
                    {{ $root.icons.action.close }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Redefinir cores</span>
            </v-tooltip>
          </div>
        </v-tabs>

        <v-tabs-items v-model="model">
          <v-tab-item value="tab1">
            <v-layout wrap>
              <v-flex
                xs12
                sm6>
                <v-card flat>
                  <v-card-text class="pb-0">
                    <h3 class="body-2 darkGray--text">
                      Principais
                    </h3>
                  </v-card-text>

                  <v-card-text>
                    <pallet-colors
                      :colors.sync="general"
                      @change="changeGeneral" />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex
                xs12
                sm6>
                <v-card flat>
                  <v-card-text class="pb-0">
                    <h3 class="body-2 darkGray--text">
                      Outras
                    </h3>
                  </v-card-text>

                  <v-card-text>
                    <pallet-colors
                      :colors="others"
                      @change="changeOthers" />
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-tab-item>

          <v-tab-item value="tab2">
            <v-flex
              x7
              sm7>
              <v-card flat>
                <v-card-text>
                  <input-image
                    value="Alterar Logo"
                    path="logo"
                    @fileChange="setLogo" />
                </v-card-text>
                <v-card-text>
                  <input-image
                    value="Alterar Mini Logo"
                    path="mini_logo"
                    @fileChange="setLogo" />
                </v-card-text>
              </v-card>
            </v-flex>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <modal
      max-width="450px"
      btn-primary-label="Confirmar"
      :show="showCloseModal"
      @submit="save"
      @close="showCloseModal = false">
      <span slot="title">Tem certeza que deseja fechar?</span>
      <p
        slot="content"
        class="subtitle-1">
        Todos os seus dados serão perdidos.
      </p>
    </modal>
  </v-flex>
</template>

<script>
  import PalletColors from '@/components/ui/PalletColors'
  import InputImage from '@/components/ui/inputs/InputImage'
  import Modal from '@/components/ui/Modal'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      PalletColors,
      InputImage,
      Modal
    },
    data () {
      return {
        model: 'tab1',
        valid: false,
        general: [
          {
            label: 'primary',
            description: 'Cor principal (topbar, botões/ações primárias, loading)',
            color: this.$vuetify.theme.themes.light.primary
          },
          {
            label: 'accent',
            description: 'Cor secundária (sidebar e ações/botões secundárias, loading)',
            color: this.$vuetify.theme.themes.light.accent
          },
          {
            label: 'secondary',
            description: 'Cor terciária (loading)',
            color: this.$vuetify.theme.themes.light.secondary
          },
          {
            label: 'success',
            description: 'Status de Sucesso',
            color: this.$vuetify.theme.themes.light.success
          },
          {
            label: 'info',
            description: 'Status de Informação',
            color: this.$vuetify.theme.themes.light.info
          },
          {
            label: 'warning',
            description: 'Status de Atenção',
            color: this.$vuetify.theme.themes.light.warning
          },
          {
            label: 'error',
            description: 'Status de Erro',
            color: this.$vuetify.theme.themes.light.error
          }
        ],
        others: [
          {
            label: 'channelEmail',
            description: 'E-email (gráficos e componentes relacionados)',
            color: this.$vuetify.theme.themes.light.channelEmail
          },
          {
            label: 'channelSms',
            description: 'SMS (gráficos e componentes relacionados)',
            color: this.$vuetify.theme.themes.light.channelSms
          },
          {
            label: 'channelLetter',
            description: 'Carta (gráficos e componentes relacionados)',
            color: this.$vuetify.theme.themes.light.channelLetter
          },
          {
            label: 'flowProposal',
            description: 'Proposta Inicial (gráficos relacionados)',
            color: this.$vuetify.theme.themes.light.flowProposal
          },
          {
            label: 'flowNegotiation',
            description: 'Negociação (gráficos relacionados)',
            color: this.$vuetify.theme.themes.light.flowNegotiation
          },
          {
            label: 'flowMediação',
            description: 'Mediação (gráficos relacionados)',
            color: this.$vuetify.theme.themes.light.flowProposal
          },
          {
            label: 'stateAgreement:',
            description: 'Status aceito (gráficos relacionados)',
            color: this.$vuetify.theme.themes.light.stateAgreement
          },
          {
            label: 'stateDisagreement',
            description: 'Status recusado (gráficos relacionados)',
            color: this.$vuetify.theme.themes.light.stateDisagreement
          }
        ],
        layout: [
          {
            label: 'dark',
            description: '',
            color: this.$vuetify.theme.themes.light.dark
          },
          {
            label: 'darkGray',
            description: '',
            color: this.$vuetify.theme.themes.light.darkGray
          },
          {
            label: 'darkPrimary',
            description: '',
            color: this.$vuetify.theme.themes.light.darkPrimary
          },
          {
            label: 'gray',
            description: '',
            color: this.$vuetify.theme.themes.light.gray
          },
          {
            label: 'grayLigth',
            description: '',
            color: this.$vuetify.theme.themes.light.grayLigth
          },
          {
            label: 'mediumGray',
            description: '',
            color: this.$vuetify.theme.themes.light.mediumGray
          },
          {
            label: 'textColor',
            description: '',
            color: this.$vuetify.theme.themes.light.textColor
          }
        ],
        settings: {
          logo: '',
          mini_logo: ''
        },
        originalTheme: { theme: {}, general: [], others: [] },
        showCloseModal: false
      }
    },
    computed: {
      ...mapGetters('global', ['getLogo', 'getMiniLogo'])
    },
    beforeMount () {
      this.settings.logo = this.getLogo
      this.settings.mini_logo = this.getMiniLogo

      Object.assign(this.originalTheme.theme, this.$vuetify.theme.themes.light)
      this.originalTheme.general = this.general.map(obj => ({...obj}))
      this.originalTheme.others = this.others.map(obj => ({...obj}))
    },
    methods: {
      changeGeneral ({label, color, index}) {
        this.valid = true
        this.$vuetify.theme.themes.light[label] = color
        this.general[index].color = color
      },
      changeOthers ({label, color, index}) {
        this.valid = true
        this.$vuetify.theme.themes.light[label] = color
        this.others[index].color = color
      },
      save () {
        this.firebase.theme.child('theme').set(this.$vuetify.theme.themes.light)
        this.firebase.theme.child('settings').update(this.settings)
        this.valid = false
        this.showCloseModal = false
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'As cores foram atualizadas'})
      },
      reset () {
        Object.assign(this.$vuetify.theme.themes.light, this.originalTheme.theme)

        this.general = this.originalTheme.general.map(obj => ({...obj}))
        this.others = this.originalTheme.others.map(obj => ({...obj}))
      },
      setLogo (file, path) {
        this.valid = true
        this.settings[`${path}`] = file
      },
      openModal () {
        this.showCloseModal = true
      }
    }
  }
</script>

<style lang="sass">
  .theme-colors-actions
    position: absolute
    top: 5px
    right: 10px

</style>
