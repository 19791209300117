<template>
  <v-container
    grid-list-xl
    class="pa-2"
    :style="setStyleEditMode">
    <v-form
      ref="formFlow"
      v-model="valid"
      lazy-validation
      class="mx-2">
      <v-layout
        v-if="displayMode"
        row
        wrap
        class="list-channels">
        <edit-actions
          v-if="actionsEdit"
          :valid="valid"
          :display-mode="isDisplayMode"
          @change="changeDisplay"
          @save="save" />

        <v-flex
          xs12
          sm12
          class="px-0">
          <display-checked
            status="emailChecked"
            label="E-mail" />
        </v-flex>

        <v-flex
          xs12
          sm12
          class="px-0">
          <display-checked
            :status="smsChecked"
            label="SMS" />
        </v-flex>

        <v-flex
          v-if="displayMode"
          xs12
          sm12
          class="px-0">
          <display-checked
            :status="letterChecked"
            label="Carta/Correio" />
        </v-flex>
      </v-layout>

      <v-layout
        v-else
        row
        wrap>
        <v-flex
          xs12
          sm12
          md6
          lg4
          class="px-2">
          <channel-card
            title="E-mail"
            color="channelEmail"
            kind="channelEmail"
            :icon="$root.icons.channel.email"
            :display-mode="onlyActived && emailDisabled"
            :checked="emailChecked"
            small
            @change="changeEmail">
            <div v-if="onlyActived && emailDisabled">
              <span class="caption white--text text-center">
                Esse Canal foi desativado, pelo Administrador da sua empresa
              </span>
            </div>
            <div v-else>
              <v-subheader class="white--text px-0">
                Detalhes dos envios
              </v-subheader>

              <div class="d-flex">
                <span class="caption white--text">1º envio</span>
                <v-spacer />
                <span class="caption white--text text-right">criação da proposta</span>
              </div>
              <div class="d-flex">
                <span class="caption white--text">2º envio</span>
                <v-spacer />
                <span class="caption white--text text-right">3 dias*</span>
              </div>
              <div class="d-flex">
                <span class="caption white--text">3º envio</span>
                <v-spacer />
                <span class="caption white--text text-right">5 dias*</span>
              </div>
              <div class="d-flex">
                <span class="caption white--text">4º envio</span>
                <v-spacer />
                <span class="caption white--text text-right">7 dias*</span>
              </div>
              <i class="caption white--text text-right d-block mt-2">enviados até a proposta ser acessada*</i>
            </div>
          </channel-card>
        </v-flex>

        <v-flex
          xs12
          sm12
          md6
          lg4
          class="px-2">
          <channel-card
            title="SMS"
            color="channelSms"
            kind="channelSms"
            :icon="$root.icons.channel.sms"
            :display-mode="onlyActived && smsDisabled"
            :checked="smsChecked"
            small
            @change="changeSms">
            <div v-if="onlyActived && smsDisabled">
              <span class="caption white--text text-center">
                Esse Canal foi desativado, pelo Administrador da sua empresa
              </span>
            </div>
            <div v-else>
              <v-subheader class="white--text px-0">
                Detalhes dos envios
              </v-subheader>
              <div v-if="emailChecked">
                <div class="d-flex">
                  <span class="caption white--text">1º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">1 hora após o email</span>
                </div>

                <div class="d-flex">
                  <span class="caption white--text">2º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">1 hora após o 2º email*</span>
                </div>
                <div class="d-flex">
                  <span class="caption white--text">3º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">1 hora após o 3º email*</span>
                </div>
                <div class="d-flex">
                  <span class="caption white--text">4º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">1 hora após o 4º email*</span>
                </div>
                <i class="caption white--text text-right d-block mt-2">enviados até a proposta ser acessada*</i>
              </div>
              <div v-else>
                <div class="d-flex">
                  <span class="caption white--text">1º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">criação da proposta</span>
                </div>
                <div class="d-flex">
                  <span class="caption white--text">2º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">3 dias*</span>
                </div>
                <div class="d-flex">
                  <span class="caption white--text">3º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">5 dias*</span>
                </div>
                <div class="d-flex">
                  <span class="caption white--text">4º envio</span>
                  <v-spacer />
                  <span class="caption white--text text-right">7 dias*</span>
                </div>
                <i class="caption white--text text-right d-block mt-2">enviados até a proposta ser acessada*</i>
              </div>
            </div>
          </channel-card>
        </v-flex>

        <v-flex
          xs12
          sm12
          md6
          lg4
          class="px-2">
          <channel-card
            title="Carta"
            color="channelLetter"
            kind="channelLetter"
            :icon="$root.icons.channel.letter"
            :display-mode="onlyActived && letterDisabled"
            :checked="letterChecked"
            only-actived
            small
            @change="changeLetter">
            <div v-if="onlyActived && letterDisabled">
              <span class="caption white--text text-center">
                Esse Canal foi desativado, pelo Administrador da sua empresa
              </span>
            </div>
            <div v-else>
              <v-subheader class="white--text px-0">
                Detalhes dos envios
              </v-subheader>
              <div class="d-flex">
                <span class="caption white--text">Envio único através dos Correios  </span>
              </div>

              <div class="d-flex mt-1">
                <i class="caption white--text text-right d-block">enviado em até 48 horas após a última notificação*</i>
              </div>
            </div>
          </channel-card>
        </v-flex>
      </v-layout>

      <div
        :class="`text-${btnPosition}`"
        class="actions-step-fixed mb-2">
        <v-btn
          v-if="btnCancelShow"
          class="textColor--text mr-2"
          depressed
          text
          @click.native="cancel">
          {{ btnCancelLabel }}
        </v-btn>
        <v-btn
          v-if="!hideSubmit"
          data-cy="channelsSubmit"
          color="accent"
          :disabled="!valid"
          @click.native="save">
          {{ submitLabel }}
        </v-btn>
      </div>

      <modal
        btn-primary-label="Sim, quero remover"
        max-width="750px"
        :show="modal"
        hide-actions
        toolbar
        @close="closeModal">
        <h2
          slot="title"
          class="title">
          Modelo de template
        </h2>
        <div slot="content">
          <img
            src="@/assets/images/fake/template.jpg"
            class="image"
            style="max-width: 100%; display: block; margin: 0 auto;">
        </div>
      </modal>
    </v-form>
  </v-container>
</template>

<script>
  import DisplayChecked from '@/components/ui/DisplayChecked'
  import ResendCommunication from '@/components/ui/ResendCommunication'
  import eventsUiMixin from '@/mixins/events/events-ui'
  import Modal from '@/components/ui/Modal'
  import ChannelCard from '@/components/ui/ChannelCard'

  export default {
    components: { ResendCommunication, Modal, DisplayChecked, ChannelCard },
    mixins: [ eventsUiMixin ],
    props: {
      onlyActived: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        valid: false,
        emailChecked: false,
        smsChecked: false,
        letterChecked: false,
        channels: {},
        modal: false,
        showDetailsEmail: false,
        emailDisabled: false,
        smsDisabled: false,
        letterDisabled: false
      }
    },
    watch: {
      items (channels) {
        this.channels = Object.assign({}, channels)
        this.isCheckedChannels()
        this.isDisplayMode = true
        this.onlyActived && this.checkDisabledChannels()
      },
      'channels.email.send' (val) {
        this.emailChecked = val
      },
      'channels.sms.send' (val) {
        this.smsChecked = val
      },
      'channels.letter.send' (val) {
        this.letterChecked = val
      }
    },
    mounted () {
      this.channels = Object.assign({}, this.items)
      this.isCheckedChannels()
    },
    methods: {
      save () {
        this.$emit('save', this.channels)
      },
      change () {
        this.$emit('change', this.channels)
      },
      cancel () {
        this.$emit('cancel')
      },
      changeEmail (_kind, val) {
        this.emailChecked = val
        this.channels = Object.assign({}, this.channels, {email: {send: val}})

        if (!this.emailChecked && !this.smsChecked && !this.letterChecked) {
          this.valid = false
        } else {
          this.valid = true
        }
        this.change()
      },
      changeSms (_kind, val) {
        this.smsChecked = val
        this.channels = Object.assign({}, this.channels, {sms: {send: val}})
        if (!this.smsChecked && !this.emailChecked && !this.letterChecked) {
          this.valid = false
        } else {
          this.valid = true
        }
        this.change()
      },
      changeLetter (_kind, val) {
        this.letterChecked = val
        this.channels = Object.assign({}, this.channels, {letter: {send: val}})
        if (!this.letterChecked && !this.smsChecked && !this.emailChecked) {
          this.valid = false
        } else {
          this.valid = true
        }
        this.change()
      },
      isCheckedChannels () {
        this.emailChecked = this.channels && this.channels.email && this.channels.email.send
        this.smsChecked = this.channels && this.channels.sms && this.channels.sms.send
        this.letterChecked = this.channels && this.channels.letter && this.channels.letter.send
      },
      checkDisabledChannels () {
        this.emailDisabled = this.channels && this.channels.email && !this.channels.email.send
        this.smsDisabled = this.channels && this.channels.sms && !this.channels.sms.send
        this.letterDisabled = this.channels && this.channels.letter && !this.channels.letter.send
      },
      showTemplate () {
        this.modal = true
      },
      closeModal () {
        this.modal = false
      }
    }
  }
</script>

<style lang="sass">
  .area-letter
    display: flex
    align-items: center
    color: $textColor

    .info-text
      margin-left: 10px

  .list-channels
    .flex
      border-bottom: 1px solid $borderColor

      &:last-child
        border-bottom: none

  .v-expansion-panel.card-checked-panel
    &::before
      box-shadow: none
</style>
